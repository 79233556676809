import { Fragment } from "react"
import Header from "../components/homePageComponents/Header"
import EmailSideBox from "../components/homePageComponents/EmailSideBox"
import SocialMediaSideBox from "../components/homePageComponents/SocialMediaSideBox"
import AboutMeSection from "../components/homePageComponents/AboutMeSection"
import TestComp from "../components/TestComp"

const Credentials: React.FC = () => {
    return (
        <Fragment>
            {/* <Header />
            <EmailSideBox />
            <SocialMediaSideBox /> */}
            {/* <main>
                <AboutMeSection />
            </main> */}
            <TestComp />
        </Fragment>
    )
}

export default Credentials
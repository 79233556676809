import { Fragment, useEffect, useState } from "react"
import "../styles/test.scss"
import { LiaLaptopCodeSolid } from "react-icons/lia"
import { FaVuejs, FaPython, FaReact } from "react-icons/fa"

const TestComp: React.FC = () => {
    const titleTypingSpeed: number = 50 // 50 ms
    const titleChangeDelay: number = 1000 // 1000 ms

    const titles: Array<string> = [
        "FRONT - END DEVELOPER",
        "DEVOPS ENGINEER",
        "AWS ASSOCIATE ARCHITECT"
    ]

    const [currentTitle, setCurrentTitle] = useState<string>('')
    const [removeTitle, setRemoveTitle] = useState<boolean>(false)
    const [currentTitleIndex, setCurrentTitleIndex] = useState<number>(0)

    useEffect(() => {
        if (currentTitle.length === 0 && removeTitle) {
            setRemoveTitle(false)
            setCurrentTitleIndex(
                (currentTitleIndex === titles.length - 1) ? 0 : (currentTitleIndex + 1)
            )
        } else {
            setTimeout(typeWriter, titleTypingSpeed)
        }
    }, [currentTitle, currentTitleIndex])

    useEffect(() => {
        if (removeTitle) {
            setTimeout(typeWriter, titleChangeDelay)
        }
    }, [removeTitle])

    const typeWriter = () => {
        const i: number = currentTitle.length - 1
        const fullTitle: string = titles[currentTitleIndex]

        if ((i + 1) < fullTitle.length) {
            if (removeTitle) {
                setCurrentTitle(currentTitle.slice(0, -1))
            } else {
                setCurrentTitle(currentTitle.concat(fullTitle.charAt(i + 1)))
            }
        } else {
            if (removeTitle) {
                setCurrentTitle(currentTitle.slice(0, -1))
            } else {
                setRemoveTitle(true)
            }
        }
    }

    return (
        <Fragment>
            <div className="top-banner">
                <div className="banner-info-container">
                    <LiaLaptopCodeSolid size={150} />
                    <h1>Akhil Goel</h1>
                    <div id="title">{currentTitle}</div>
                </div>
            </div>

            <section className="about-me">
                <div className="section-heading">About me</div>
                <div className="intro-card-container">
                    <div className="card">
                        <img height={250} src="img/my_pic.jpg" />
                        <div className="icons-container">
                            <FaReact size={50} color={"#03A9F4"} />
                            <FaPython size={50} color={"#3F51B5"} />
                            <FaVuejs size={50} color={"#4CAF50"} />
                        </div>
                    </div>
                </div>
                <div className="intro-description">

                </div>
            </section>
        </Fragment>
    )
}

export default TestComp
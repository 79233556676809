import "./styles/index.scss"

import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import Credentials from './pages/Credentials'

const root = createRoot(document.getElementById('root'))

root.render(
  <BrowserRouter>
    <Credentials />
  </BrowserRouter>
)